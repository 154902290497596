document.addEventListener('DOMContentLoaded',function(){

    class lazyLoad {
        constructor(domElements){
            this.dom            = domElements;
            this.pictureTags    = document.querySelectorAll(this.dom);
            this.browserLoader  = false;
            this.init();
        }
        init () {
            if ('IntersectionObserver' in window) {
                this.observeOption();
            } else {
                this.notSupported();
            }
        }

        observeOption() {
            var lazyImages = [].slice.call(this.pictureTags);
            let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
                entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    if(entry.target.tagName == "PICTURE") {
                        var imgTag = lazyImage.getElementsByTagName('img')[0];
                        var sources = lazyImage.getElementsByTagName('source');
                        imgTag.src = imgTag.dataset.src;
                        imgTag.removeAttribute('width');
                        imgTag.removeAttribute('height');
                        for(var i = 0; i < sources.length; i++) {
                            sources[i].srcset = sources[i].dataset.srcset;
                        }
                        lazyImage.classList.remove("lazy");
                        lazyImageObserver.unobserve(lazyImage);
                    } else {
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImage.srcset = lazyImage.dataset.srcset;
                        lazyImage.classList.remove("lazy");
                        lazyImageObserver.unobserve(lazyImage);
                    }
                }
                });
            });
            lazyImages.forEach(function(lazyImage) {
                lazyImageObserver.observe(lazyImage);
            });
        }
        notSupported() {
            for(var i = 0; i < this.pictureTags.length; i++) {
                var imgTagNotSupported = this.pictureTags[i].getElementsByTagName('img')[0];
                var sourcesTagNotSupported = this.pictureTags[i].getElementsByTagName('source');

                imgTagNotSupported.src = imgTagNotSupported.dataset.src;

                for(var j = 0; j < sourcesTagNotSupported.length; j++) {
                    sourcesTagNotSupported[j].src = sourcesTagNotSupported[j].dataset.srcset;
                }

                this.pictureTags[i].classList.remove('lazy');
            }
        }
    }

    const lazyLoader = new lazyLoad('.lazy');
})